<template>
  <div class="yqgkcCon jgzsCov" >
    <div ref="echartsContainer" style="width: 100%; height: 260px;position: relative;z-index: 1;"></div>
    
  </div>
</template>

<script>
import * as echarts from 'echarts';
 
export default {
  props:{
    clczCoverValue:Object,
  },
  name: 'jgzsCov',
  
  mounted() {
    let nunmList = this.clczCoverValue.details
    let nameList = nunmList.map(item=>item.detailTitle)
    let valueOneList = nunmList.map(item=>item.valueOne)
    let valueTwoList = nunmList.map(item=>item.valueTwo)
    // 初始化echarts实例
    var myChart = echarts.init(this.$refs.echartsContainer);
 
    // 指定图表的配置项和数据
    var option = {
    grid: {
        top: "15%",
        bottom: "10%"//也可设置left和right设置距离来控制图表的大小
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(13, 64, 71, 0.50)",
      borderColor: "rgba(143, 225, 252, 0.60)",
      padding: 8,
      textStyle: {
        color: "#fff",
      },
    },
    legend: {
        data: ["年总产值", "年总产量"],
        top: "1%",
        textStyle: {
            color: "#ffffff"
        }
    },
    xAxis: {
        data: nameList,
        axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
                        color: '#01FCE3'
                        }
        },
        axisTick: {
            show: true //隐藏X轴刻度
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: "#ebf8ac" //X轴文字颜色
            }
        },
         
    },
    yAxis: [{
            type: "value",
            name: "万元/年",
            nameTextStyle: {
                color: "#ebf8ac"
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: true
            },
            axisLine: {
                show: false,
                lineStyle: {
                            color: '#FFFFFF'
                            }
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ebf8ac"
                }
            },
             
        },
        {
            type: "value",
            name: "万斤/年",
            nameTextStyle: {
                color: "#ebf8ac"
            },
            position: "right",
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                show: true,
                formatter: "{value} ", //右侧Y轴文字显示
                textStyle: {
                    color: "#ebf8ac"
                }
            }
        },
        {
            type: "value",
            gridIndex: 0,
            min: 50,
            max: 100,
            splitNumber: 8,
            splitLine: {
                show: false
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            splitArea: {
                show: true,
                areaStyle: {
                    color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"]
                }
            }
        }
    ],
    series: [{
            name: "年总产值",
            type: "line",
            yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 2, //标记的大小
            itemStyle: {
                //折线拐点标志的样式
                color: "#30D1FF"
            },
            lineStyle: {
                color: "#30D1FF"
            },
            areaStyle:{
                color: "rgba(5,140,255, 0.2)"
            },
            data: valueOneList
        },
        {
            name: "年总产量",
            type: "bar",
            barWidth: 15,
            itemStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: "#24FFB1"
                        },
                        {
                            offset: 1,
                            color: "#24FFB1"
                        }
                    ])
                }
            },
            data: valueTwoList
        }
    ]
    };
    // 动态显示tootip
      var faultByHourIndex = 0; //播放所在下标
      setInterval(function () {
        //使得tootip每隔三秒自动显示
        myChart.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: faultByHourIndex,
        });
        faultByHourIndex++;
        if (faultByHourIndex >= option.series[0].data.length) {
          faultByHourIndex = 0;
        }
        // if (faultByHourIndex >= option.series[1].data.length) {
        //     faultByHourIndex = 0;
        // }
        // if (faultByHourIndex >= option.series[2].data.length) {
        //     faultByHourIndex = 0;
        // }
        // if (faultByHourIndex >= option.series[3].data.length) {
        //     faultByHourIndex = 0;
        // }
      }, 1500);
    // 使用刚指定的配置项和数据显示图表。
    myChart.setOption(option);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";

</style>
