<template>
  <div class="yqgkcCon" style="position: relative;">
    <img src="@/assets/images/rydizuo.png" class="rydizuoImg" alt="">
    <el-carousel :interval="4000" type="card" indicator-position="none" height="260px" style="margin-top:10px;" @change="lqban">
      <el-carousel-item v-for="(item,index) in photo" :key="index" >
        <div class="marcov">
          <div class="rylist">
            <viewer :images="photo" >
              <img :src="urlimg+item.detailPics" v-for="(item,index) in photo" v-show="index==0" style="position: absolute;left:20px;top:20px;width:110px;height:163px;opacity: 0;" :key="index" alt="">
            </viewer>
            <img :src="urlimg+item.detailPics"  class="ryzsImg" alt="">
            <span class="rylist_tit" v-show="imgimdxe==index">{{item.detailTitle}}</span>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props:{
    honorCoverValue:Object,
    urlimg:String
  },
  name: "ryzs",
  data(){
    return{
      imgimdxe:'',
      photo:[]
    }
  },
  mounted(){
    this.photo= this.honorCoverValue.details
  },
  methods:{
    lqban(e){
      this.imgimdxe = e
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";
.marcov{
  padding: 0 10px;
  box-sizing: border-box;
}


.rylist {
  width: 137px!important;
  height: 194px!important;
  background-image: url(@/assets/images/rybg.png); /* 替换为你的图片路径 */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  //  position: relative;
  // background: url(@/assets/images/rybg.png)no-repeat 100% 100%;
  // background-size: cover;
  // background-position: center;
  // margin: 0 auto;
  .rylist_tit{
    font-size: 14px;
    color: #55FFDD;
    display: block;
    margin-top: 50px;
  }
}
.rydizuoImg{
  position: absolute;
  left: 0;
  top: 155px;
}
.ryzsImg{
  width: 110px;
  height: 153px;
  margin-top: 22px;
  margin-right: 2%;
}

.el-carousel__item--card {width: 147px;margin-left: 8%;}

::v-deep .el-carousel__mask{
  background-color: rgba(0, 0, 0, 0);
}

</style>
