<template>
<ScaleBox :width="1920" :height="1080" bgc="transparent" :delay="100" :isFlat="true">
        <div style="width:1920px;height:1080px;position: absolute;op:0px;left:0px;z-index: 55555;" class="hombACk">
          <!-- <video src="@/assets/images/loginback.mp4" type="video/mp4" autoplay muted loop ></video> -->
          <video autoplay loop muted preload 
            style="width: 100%; height: 100%; object-fit: fill">
            <source src="@/assets/images/loginback.mp4">
          </video>
          <span class="logintltle">平定县特优产业（谷子）数智农业大数据平台</span>
          <el-form
              class="loginipt demo-ruleForm"
              :rules="rules"
              :model="rulesForm"
              status-icon
              ref="ruleForm"
              autocomplete="off"
          >
            <span class="hydl">欢迎登录</span>
              <!-- <img src="@/assets/images/login_title_img.png" alt="" class="login_title_img"> -->
            <el-form-item label="" prop="name" class="loginipt_list1">
              <img src="@/assets/images/login_icon_zhanghu1.png" alt="" class="login_icon_zhanghu">
              <el-input type="text" v-model="rulesForm.name" class="login_iptnum" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password" class="loginipt_list1">
              <img src="@/assets/images/login_icon_mima1.png" alt="" class="login_icon_zhanghu">
              <el-input placeholder="请输入密码" type="text" show-password  autocomplete="off" v-model="rulesForm.password" class="login_iptnum"  ></el-input>
            </el-form-item>
            <!-- <el-form-item label="" prop="yzm" class="loginipt_list1 posit">
              <img src="@/assets/images/login_icon_mima1.png" alt="" class="login_icon_zhanghu">
              <el-input type="text" v-model="rulesForm.yzm" class="login_iptnum" placeholder="请输入验证码" ></el-input>
              <div class="login-code">
                <img :src="codeUrl" @click="getCode" class="login-code-img" />
              </div>
            </el-form-item> -->
            <el-form-item>
              <el-button class="login_btn_anniu"  ref="loginForm"  @keyup.enter="submitForm('ruleForm')" type="primary" @click="submitForm('ruleForm')" >登  录</el-button>
            </el-form-item>
          </el-form>
        </div>
    </ScaleBox>

</template>

<script>
import { login, getCodeInfo } from '@/api';
 import ScaleBox from "vue2-scale-box";
export default {
  components: {
    ScaleBox,
  },
  data() {
    return {
      codeUrl:'',
      //存储数据的对象
      rulesForm:{
        name:'',
        password:'',
        yzm:'',
      },
      rules:{
        // name:[
        //   {required:true,message:'请输入账号',trigger:"blur"}
        // ],
        // password:[
        //   {required:true,message:'请输入密码',trigger:"blur"}
        // ],
        // yzm:[
        //   {required:true,message:'请输入验证码',trigger:"blur"}
        // ]
      }
    };
  },
  created(){
    //  this.getCode()
    // 设置整点定时发送请求
    // setInterval(() => {
    //   this.getCode()
    // }, 30000);
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.submitForm(); // 定义的登录方法
      }
    },
    submitForm(formName){
      if (this.rulesForm.name=='') {
        this.$message.error('请输入账号');
        return;
      }
      if (this.rulesForm.password=='') {
        this.$message.error('请输入密码');
        return;
      }
      localStorage.setItem('myname', this.rulesForm.name);
      localStorage.setItem('mypassword', this.rulesForm.password);
      if (this.rulesForm.name=='pdadmin'&&this.rulesForm.password=='pd2024') {
              window.location.href= '/loading';
            } else{
          this.$message.error('请输入正确的账号密码！');
        }
      //  if (this.rulesForm.yzm=='') {
      //   this.$message.error('请输入验证码');
      //   return;
      // }
      // this.$refs.ruleForm.validate((valid)=>{
      //     if(valid){
      //         //如果校检通过，在这里向后端发送用户名和密码
      //         login({
      //           username: this.rulesForm.name,
      //           password: this.rulesForm.password,
      //           captcha: this.rulesForm.yzm,
      //           checkKey:1629428467008,
      //         }).then((data)=>{
      //           if(data.code === 200){
      //             window.sessionStorage.setItem('token', data.result.token)
      //             window.sessionStorage.setItem('password', JSON.stringify(data.result.userInfo));
      //             // localStorage.setItem('token',data.result.token);
      //               window.location.href= '/Home';
      //           }else{
      //                this.$message.error(data.message)
      //           }
      //         });
      //     }else{
      //       console.log("error submit!!");
      //       return false;
      //     }
      // });
    },
     getCode(){
      let now = new Date().getTime();
      getCodeInfo(now).then((res) =>{
        if (res.success == true) {
          this.codeUrl = res.result
          console.log(this.codeUrl)
          // this.codeUrl = res.data.img
        }
      })
    }
  }
}
</script>

<style scoped>
    video{
      z-index:-100;
      width: 100%;
      height:100%;
    }
    .loginipt{
        /* position: absolute;
        top: 28%;
        right: 33%; */
        margin: 0 auto;
        margin-top: 440px;
        width: 30.3%;
        height: 44%;
        /* background: url(../../assets/images/login_bg_img.png)no-repeat; */
	      background-size: 100% 100%;
        overflow: hidden;
    }
    .hydl{
      font-size: 20rpx;
       display: block;
        text-align: center;
        font-size: 24px;
        color: #1df59f;
        font-weight: bold;
        letter-spacing: 0.4em;
    }
    .logintltle{
        position: absolute;
        top: 240px;
        right: 0%;
        width: 100%;
        display: block;
        text-align: center;
        font-size: 50px;
        color: #ffffff;
        font-weight: bold;
        text-shadow: 0px 2px 8px rgb(0, 85, 124);
        font-style: italic;
        display: inline-block;
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        letter-spacing: 0.1em;
        content: attr(bg-content);
        position: absolute;
        background: linear-gradient(180deg, #fcfeff 11.46%, #1ae996 70.31%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
    }
    @keyframes shake{
      10%, 90%{
      transform: translate3d(-1px,0,0);
      }
      20%, 80%{
      transform: translate3d(2px,0,0);
      }
      30%, 50%, 70%{
      transform: translate3d(-4px,0,0);
      }
      40%, 60%{
      transform: translate3d(4px,0,0);
      }
    }
    .login_title_img{
        display: block;
        margin: 0 auto;
        margin-top: 1.6vw;
    }
    
    .loginipt_list1{
        width: 60%;
        height: 44px;
        margin: 0 auto;
        margin-top: 28px;
        background: #013a1a;
        border: 1px solid #0c7426;
        box-shadow: inset 0 0 13px 0 rgba(1, 17, 13, 0.8);
        border-radius: 4px;
        position: relative;
        overflow: hidden;
    }
    .loginipt_list1:hover{
      border: 1px solid #09ac11;
    }
    .login_btn_anniu{
        width: 60%;
        height:50px;
        /* line-height: 3vw;s */
        text-align: center;
        margin-top: 24px;
        background: url(../../assets/images/login_btn_anniu.png)no-repeat;
        background-size: 100% 100%;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        border: none;
        opacity: 0.6;
        font-weight: bold;
    }
    .login_btn_anniu:hover{
        width: 60%;
        height: 50px;
        text-align: center;
        margin-top: 24px;
        background: url(../../assets/images/login_btn_anniu.png)no-repeat;
        background-size: 100% 100%;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        border: none;
        opacity: 1;
        font-weight: bold;
    }
    .posit{
      position: relative;
    }
    .login_icon_zhanghu{
        width: 20px;
        margin-top: 9px;
        margin-left: 3%;
        float: left;
    }
    .login-code{
      width: 120px;
      height: 38px;
      position: absolute;
      top:2px;
      right: 1px;
      background: #0B2136;
    }
    .login-code-img{
      width:100%;
      height:100%;
    }
    .login_iptnum{
        width: 88%;
        height: 40px;
        line-height: 40px;
        padding-left: 3%;
        background: none;
        border: none;
        color: #51ff8b;
        font-size: 16px;
        float: left;
    }
    div /deep/ .el-input__inner {
        border: none;
        width: 88%;
        height: 44px;
        line-height: 40px;
        padding-left: 3%;
        background: none!important;
        border: none!important;
        color: #01c92c;
        font-size: 16px;
        float: left;
    }
  

</style>

<style scoped lang="less">
.el-input__inner{
  color:#00ff37!important;
}
   input:-webkit-autofill {

    background: #0b361e !important;

    color: #00ff73!important;

     -webkit-box-shadow: 0 0 0px 1000px #0b361c inset !important;
     -webkit-text-fill-color:#fff; 

   }
   .el-input__inner[type="password"] {
    background-color: transparent;
  }
 input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-text-fill-color: #00ff95 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color:transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s; 
  }
  video{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      }
      video::-webkit-media-controls {
      display: none !important;
      }
      video::-webkit-media-controls-enclosure {
      display: none !important;
      }
      video::-webkit-media-controls-overlay-cast-button {
      display: none !important;
    }
    .el-input__inner::placeholder {
        color: #008d1f;
    }

</style>
