<template>
  <div class="yqgkcCon cyzxOver" >
    <div class="block" @click.stop="cyzxshow($event)">
       <vue-seamless-scroll :data="cyzuList"  :class-option="optionSetting">
          <div class="tcgd2" v-for="(item,index) in cyzuList" :key="index" :data-item="JSON.stringify(item)">
            <el-timeline>
              <el-timeline-item timestamp="" placement="top" color="#01E393">
                  <el-card class="cyzxList" >
                    <img src="@/assets/images/xiangqing.png" alt="" class="xiangqing">
                    <div >
                      <span class="cyzxLtTit">{{item.detailTitle}}</span>
                      <span class="cyzxLtTime"><i class="el-icon-time"></i>时间：{{item.valueOne}}</span>
                      <span class="cyzxLtMore">详情<i class="el-icon-d-arrow-right"></i></span>
                    </div>
                  </el-card>
              </el-timeline-item>
              </el-timeline>
          </div>
       </vue-seamless-scroll>
    </div>
    <el-dialog
      width="70%"
      top="160px"
      :visible.sync="qczddialogTableVisible"
      :modal-append-to-body="false"
    >
      <div class="tz_cov_chanye" style="height: 670px">
        <img
          src="@/assets/images/guanbi.png"
          class="guanbiann"
          @click="qcguanbi"
          alt=""
        />
        <div class="tz_cov_topchanye">
          <span class="tz_cov_top_tit">产业资讯</span>
        </div>
        <el-row class="tz_cov_topyqjs">
          <el-col :span="14">
            <div class="grid-content bg-purple ycboxlt">
              <el-carousel
                indicator-position="outside"
                :interval="2000"
                height="540px"
                width="100%"
              >
                <el-carousel-item v-for="(item,index) in yqjsbanlist" :key="index">
                  <viewer :images="yqjsbanlist" >
                    <img :src="urlimg+item" v-for="(item,index) in yqjsbanlist" :key="index" v-show="index==0" style="background:#ff0000;position: absolute;left:0px;top:0px;width: 100%; height: 100%; z-index: 2;opacity: 0;"  alt="">
                  </viewer>
                  <img
                    :src="urlimg+item"
                    alt=""
                    style="width: 100%; height: 100%; margin-top: 0.5vw"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="grid-content bg-purple-light">
              <span class="titZX">{{valueTc.detailTitle}} </span>
              <p class="tcgldw">
                  {{valueTc.contentOne}} 
              </p>
             
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    cygkcyzxListValue:Array,
    urlimg:String
  },
  name: "cyzx",
  data(){
    return{
      qczddialogTableVisible: false,
      cyzuList:this.cygkcyzxListValue,
      yqjsbanlist:[],
      valueTc:''
    }
  },
  computed:{
    optionSetting(){
      return{
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        autoPlay: true, // 是否自动滚动
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  methods:{
    cyzxshow(e) {
      const lisst = e.target.closest(".tcgd2"); // 定位元素
      this.qczddialogTableVisible = true;
      if (lisst) { // 是否是滚动组件的某一行/列
          let itemlist  = lisst.dataset.item
          this.valueTc = JSON.parse(itemlist)
          this.yqjsbanlist = this.valueTc.detailPics.split(',')
        }
    },
     qcguanbi() {
        this.qczddialogTableVisible = false;
      },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";
.cyzxOver{
  overflow: auto;
}
/* 定义滚动条样式 */
.cyzxOver::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background-color: rgba(240, 240, 240, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.cyzxOver::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .0);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .0);
}
/*定义滑块 内阴影+圆角*/
.cyzxOver::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(36, 132, 109, .8);
	background-color: rgba(36, 132, 109, .8);
}
.el-timeline-item{
  padding-bottom: 0px;
}
.cyzxList{
  width: 100%;
  height: 100px;
  border: none;
  background-color: rgba(2, 46, 52, 0.5);
  // background-image: url(@/assets/images/xiangqing.png);
  // background-repeat: no-repeat;
  // background-size: 3% 50%;
  position: relative;
  cursor: pointer;
  .xiangqing{
    width: 268px;
    height: 51px;
    position: absolute;
    top: 22px;
    left: 5px;
  }
  .cyzxLtTit{
    color: #55FFDD;
    font-size: 16px;
    text-align: left;
    display: block;
    margin-left: 50px;
    width: 85%;
    display: block;
        overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cyzxLtTime{
    color: #eee;
    font-size: 14px;
    text-align: left;
    display: block;
    margin-left: 50px;
    margin-top: 16px;
  }
  .cyzxLtMore{
    font-size: 14px;
    color: #55FFDD;
    position: absolute;
    right: 20px;
    top: 44px;
  }
}

// 弹出开始
:deep .el-dialog {
    position: relative;
    margin: 0 auto;
    background: none;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    box-sizing: border-box;
    width: 50%;
    /* margin-left: 22%; */
}
:deep .el-dialog__header{
	display: none;
}
.tz_cov_chanye{
	width: 100%;
	height: 42vw;
	/* background: url(../../assets/images/tccovbak.png) no-repeat;
    background-size: 100% 100%; */
    background: rgb(1 26 12 / 70%);
    border-radius: 10px;
    border: 1px solid #339D94;
    text-align: center;
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px rgba(14,252,255,0.8);
	position: relative;
	overflow: hidden;
}
.guanbiann{
	width: 28px;
	height: 28px;
	position: absolute;
	right: 2%;
	top: 0.5vw;
	z-index: 9999999999;
	cursor: pointer;
}
.tz_cov_topchanye{
	width: 100%;
	height:48px;
	background: url(../../assets/images/tctopback.png)no-repeat ;
	background-size: 100% 100%;
	text-align: center;
	line-height: 48px;
	margin-bottom: 20px;
}
.tz_cov_top_tit{
	font-size: 20px;
    font-family: douyuFont;
    color: #fff;
    text-align: center;
}
.titZX{
  font-size: 22px;
  display: block;
  font-weight: bold;
  text-align: center;
  color: #55FFDD;
  margin-top: 20px;
}
.tcgldw{
	font-size: 18px;
    color: #fff;
    width: 98%;
    margin: 0 auto;
    margin-top: 10px;
    line-height: 38px;
    text-align: left;
    text-indent: 2em;
    height: 516px;
    overflow: auto;
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 15; */
}
/* 定义滚动条样式 */
.tcgldw::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background-color: rgba(240, 240, 240, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.tcgldw::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .0);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .0);
}
/*定义滑块 内阴影+圆角*/
.tcgldw::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(36, 132, 109, .8);
	background-color: rgba(36, 132, 109, .8);
}

.ycboxlt{
    width: 94%; margin-left: 2%;
    background: url(../../assets/images/tckuang.png)no-repeat ;
	background-size: 100% 100%;
    padding: 10px;
}
.tz_cov_topyqjs{
    width: 100%;
	  height:600px;
}
.tz_cov_top{
    width: 100%;
	  height:600px;
    overflow: auto;
    animation: fadeInLeft 1s 0.02s ease backwards;
}

// 弹出结束
/* // 竖线样式 */
::v-deep .el-timeline-item__tail {
  position: absolute!important;
  top: 10px!important;
  left: 1%!important;
  height: 100%!important;
  width: 2px!important;
  opacity: 0.2!important;
  border-left: solid 3px #00FBBE!important;
}
/* // 圆点样式 */
::v-deep .el-timeline-item__node--normal {
  left: 1%;
  width: 10px;
  height: 10px;
  background: url(../../assets/images/qxyj.png)no-repeat 100% 100%; 
}
::v-deep.el-timeline .el-timeline-item:last-child .el-timeline-item__tail{
  position: absolute!important;
  top: 10px!important;
  left: 2%!important;
  height: 100%!important;
  width: 2px!important;
  opacity: 0.2!important;
  border-left: solid 3px #00FBBE!important;
  display: block;
}
</style>
