import Vue from "vue";
import VueRouter from "vue-router";
import Loading from "../views/loading.vue";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login/index.vue";
Vue.use(VueRouter);

const routes = [
  {
      path:"/Login",
      component:Login,
      meta: {
          title: '',
          requireAuth: ''  // 是否需要判断是否登录,这里是需要判断
      },
      name:"login"
  },
  {
    path: "/loading",
    name: "loading",
    component: Loading,
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
  {
    path: "/zjzt",
    name: "zjzt",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JyztView.vue"),
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
  {
    path: "/znwl",
    name: "znwl",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/znwlView.vue"),
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
  {
    path: "/cyshow",
    name: "cyshow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/cyShow.vue"),
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
  {
    path: "/cyqyShow",
    name: "cyqyShow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/cyqyShow.vue"),
    meta: {
      title: '',
      requireAuth: true  // 是否需要判断是否登录,这里是需要判断
     },
  },
];

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
