<template>
  <div class="hello">
    <div class="listTit">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";
</style>
