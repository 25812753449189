<template>
	<div class="yqgkcCon">
		<video  id="myVideo" style="position:relative;width:450px;height:260px;" poster="@/assets/images/videoBack.png"  :controls="controls" :autoplay="autoplay"  playsinline  loop >
			<source :src="videoShow.detailVideos" type="video/mp4">
		</video>
	</div>
</template>

<script>
	export default {
		props:{
			ltqyCoverValue:Object,
    		// urlimg:String
		},
		name: "ryzs",
		data() {
			return {
				controls:false,
				autoplay:true,
				video:{},
				videoShow:'',
				urlimg:this.urlImg,
				timers:null
			}
		},
		mounted() {
			this.videoShow = this.ltqyCoverValue.details[0]
			this.$nextTick(()=>{
				this.video = document.getElementById('myVideo')
				// this.video.load()
				this.autoplay = true
				this.controls = true 
				this.timers = new Date().getTime()
			})
		},
		methods:{
		} 
	};
</script> 

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	video{ 
		width: 450px;
		object-fit: fill;
	}
	video::-webkit-media-controls {
		width: 450px;
	}
</style>