<template>
  <div class="home_btmCon">
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onetopzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group01.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{fus1.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(fus1.valueOne)" :key="parseInt(fus1.valueOne)" duration="2000"  ></animate-number> {{fus1.valueTwo}}</span>
      </div>
    </div>
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onetopzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group06.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{fus2.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(fus2.valueOne)" :key="parseInt(fus2.valueOne)" duration="2000"  ></animate-number> {{fus2.valueTwo}}</span>
      </div>
    </div>
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onetopzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group07.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{fus3.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(fus3.valueOne)" :key="parseInt(fus3.valueOne)" duration="2000"  ></animate-number> {{fus3.valueTwo}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    fxStatistcCoverValue:Object,
  },
  name: "HelloWorld",
  data(){
    return{
      fus1:'',
      fus2:'',
      fus3:''
    }
  },
  mounted(){
    this.fus1 = this.fxStatistcCoverValue.details[0]
    this.fus2 = this.fxStatistcCoverValue.details[1]
    this.fus3 = this.fxStatistcCoverValue.details[2]
  },
  methods:{
    formatter(num) {
      if (num) {
        return num.toFixed(0)
      }
      
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";
</style>
