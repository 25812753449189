<template>
  <div class="yqgkcCon">
    <div style="height:120px;overflow:hidden;">
      <vue-seamless-scroll :data="orgIntroList"  :class-option="optionSetting">
          <p class="yqgkcCon_text" v-for="(item,index) in orgIntroList" :key="index">  
            {{item}}
          </p>
      </vue-seamless-scroll>
     </div>
    <el-carousel indicator-position="outside" height="124px">
      <el-carousel-item v-for="item in photo" :key="item">
          <viewer :images="photo" class="yqgkcCon_imglist">
            <img
              v-for="(val, index) in photo"
              :src="urlimg+val"
              :key="index"
            />
          </viewer>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props:{
    cygkCoverValue:Object,
    urlimg:String
  },
  name: "ykgkCov",
  data(){
    return{
      photo:[],
      orgIntroList:[]
    }
  },
  computed:{
    optionSetting(){
      return{
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        autoPlay: true, // 是否自动滚动
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  mounted(){
    let num = this.cygkCoverValue.orgPics
    this.orgIntroList = this.cygkCoverValue.orgIntro.split()
    this.photo = num.split(",");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";
</style>
