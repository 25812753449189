<template>
  <div class="home_topCon">
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onebottomzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group01.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{detailsList0.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(detailsList0.valueOne)" :key="parseInt(detailsList0.valueOne)" duration="2000"  ></animate-number> {{detailsList0.valueTwo}}</span>
      </div>
    </div>
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onebottomzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group02.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{detailsList1.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(detailsList1.valueOne)" :key="parseInt(detailsList1.valueOne)" duration="2000"  ></animate-number> {{detailsList1.valueTwo}}</span>
      </div>
    </div>
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onebottomzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group03.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{detailsList2.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(detailsList2.valueOne)" :key="parseInt(detailsList2.valueOne)" duration="2000"  ></animate-number> {{detailsList2.valueTwo}}</span>
      </div>
    </div>
    <div class="home_topCon_list">
      <div class="home_topCon_list_img animate__animated animate__headShake animate__slower 5s animate__infinite infinite">
          <img src="@/assets/images/onebottomzhuan.png" alt="" class="btmimg">
          <img src="@/assets/images/Group04.png" alt="" class="topimg">
      </div>
      <div class="home_topCon_list_num">
          <span class="tit">{{detailsList3.detailTitle}}</span>
          <span class="num"><animate-number from="0" :to="parseInt(detailsList3.valueOne)" :key="parseInt(detailsList3.valueOne)" duration="2000" :formatter="formatter"  ></animate-number> {{detailsList3.valueTwo}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    plantStatistcCoverValue:Object
  },
  name: "HelloWorld",
  data(){
    return{
      imgimdxe:'',
      detailsList0:'',
      detailsList1:'',
      detailsList2:'',
      detailsList3:''
    }
  },
  mounted(){
    this.detailsList0 = this.plantStatistcCoverValue.details[0]
    this.detailsList1 = this.plantStatistcCoverValue.details[1]
    this.detailsList2 = this.plantStatistcCoverValue.details[2]
    this.detailsList3 = this.plantStatistcCoverValue.details[3]
  },
  methods:{
    formatter(num) {
      if (num) {
        return num.toFixed(0)
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "@/assets/css/home.css";
</style>
