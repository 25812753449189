<template>
  <div class="yqgkcCon zzmjCov">
     <div ref="columnpreEchart" style="width: 50%; height: 260px;"></div>
     <div class="zzmjList">
      <div v-for="(item,index) in barChartList" :key="index">
          <span class="zzmjListTit">{{item.detailTitle}}</span>
          <span class="zzmjListNum"> {{item.valueOne}}<span class="danwei">{{item.valueTwo}}</span> <span class="zzmjSM">较上年 <span class="zzmjcolo">{{item.valueThree}}</span> </span> </span>
      </div>
     </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    props:{
    zzmjCoverValue:Object,
  },
  name: "zzmjCov",
  data(){
    return{
      barChartList:this.zzmjCoverValue.details
    }
  },
  mounted() {
    let barChartList = this.zzmjCoverValue.details
   // 基于准备好的dom，初始化echarts实例
    var myChart = echarts.init(this.$refs.columnpreEchart);
    let data = [];
    let currentIndex = 0;
    let oldIndex;
    var color = ['#E44660', '#E4A746', ' #FFFFFF', '#21BAD6', '#25EFBD', '#ffeec9', '#aefffe', '#bfc5ff', '#d7a8ff', '#93ffd0'];
    for (var i = 0; i < barChartList.length; i++) {
        data.push(
            {
                value: barChartList[i].valueOne,
                name: barChartList[i].detailTitle,
                itemStyle: {
                    normal: {
                        borderWidth: 1,
                        shadowBlur: 4,
                        borderRadius: 0, // 圆角
                        borderColor: color[i],
                        shadowColor: color[i],
                        color: color[i],
                    }
                }
            },
            {
                value: 4,
                name: '',
                itemStyle: {
                    normal: {
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        },
                        color: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgba(0, 0, 0, 0)',
                        borderWidth: 0
                    }
                }
            }
        );
    }
    var seriesOption = [
        {
            name: 'pie2',
            type: 'pie',
            radius: [58, 60],
            color: '#068898',
            label: {
                show: false
            },
            labelLine: {
                show: false
            },
            data: [1],
            silent: true
        },
        {
            name: 'pie1',
            type: 'pie',
            radius: [70, 80],
            grid: {
                containLabel: true
            },
            label: {
                show: false,
                position: 'center',
                formatter: '{c_style|{c}亩}\n{b_style|{b}}',
                rich: {
                    b_style: {
                        fontSize: 16,
                        fontWeight: 400,
                        color: '#fff'
                    },
                    c_style: {
                        padding: [0, 0, 10, 0],
                        fontSize: 14,
                        fontWeight: 'bold',
                        color: '#FDD015'
                    }
                }
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '14',
                    fontWeight: 'normal'
                }
            },
            labelLine: {
                show: false
            },
            data: data
        }
    ];
    let option = {
        color: color,
        tooltip: {
            show: false
        },
        series: seriesOption
    }

    window.addEventListener("resize", function () {
        myChart.resize();
    });
    
    handleChartLoop(option, myChart);//定义名称
    // 饼图自动轮播
    function handleChartLoop(option, myChart) {
        if (!myChart) {
            return
        }
        let currentIndex = 0 // 当前高亮图形在饼图数据中的下标
        let changePieInterval = setInterval(selectPie, 1000) // 设置自动切换高亮图形的定时器

        // 取消所有高亮并高亮当前图形
        function highlightPie() {
            // 遍历饼图数据，取消所有图形的高亮效果
            for (var idx in option.series[1].data) {
                myChart.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 1,
                    dataIndex: idx
                })
            }
            // 高亮当前图形
            myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 1,
                dataIndex: currentIndex
            })
        }

        // 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
        myChart.on('mouseover', (params) => {
            clearInterval(changePieInterval)
            currentIndex = params.dataIndex
            highlightPie()
        })

        // 用户鼠标移出时，重新开始自动切换
        myChart.on('mouseout', (params) => {
            if (changePieInterval) {
                clearInterval(changePieInterval)
            }
            changePieInterval = setInterval(selectPie, 1000)
        })

        // 高亮效果切换到下一个图形
        function selectPie() {
            var dataLen = option.series[1].data.length
            currentIndex = (currentIndex + 2) % dataLen
            highlightPie()
        }
    }
    // 绘制图表
    myChart.setOption(option);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.zzmjCov{
  position: relative;
}
.zzmjList{
  position: absolute;
  top: 0px;
  right: 2%;
  width: 50%;
  height: 250px;
  overflow: auto;
}
/* 定义滚动条样式 */
.zzmjList::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background-color: rgba(240, 240, 240, 0.1);
}
/*定义滚动条轨道 内阴影+圆角*/
.zzmjList::-webkit-scrollbar-track {
	box-shadow: inset 0 0 0px rgba(240, 240, 240, .0);
	border-radius: 10px;
	background-color: rgba(240, 240, 240, .0);
}
/*定义滑块 内阴影+圆角*/
.zzmjList::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 0px rgba(36, 132, 109, .8);
	background-color: rgba(36, 132, 109, .8);
}
.zzmjListTit{
  font-size: 14px;
  color: #EFF0F1;
  display: block;
  text-align: left;
  margin-top: 10px;
}
.zzmjListNum{
  color: #E7BF17;
  font-size: 24px;
  text-align: left;
  display: block;
  margin-top: 6px;
  width: 100%;
  font-family: 'UnidreamLED';
  .danwei{
    font-size: 14px;
  }
  .zzmjSM{
    color: #fff;
    font-size: 14px;
    .zzmjcolo{
      color: #ff0000;
    }
  }
}
</style>
