<template>
  <div class="home" >
    <div class="home_left">
      <transition name="yqgk" appear >
          <TitWorld msg="产业概况" />
      </transition>
      <transition name="yqgk" appear >
          <YkgkCov v-if="cygkCover" :cygkCoverValue="cygkCover" :urlimg="urlimg" />
      </transition>
      <transition name="dlhj" appear >
          <TitWorld msg="富硒荣耀" />
      </transition>
      <transition name="dlhj" appear >
          <RyzsCov v-if="honorCover" :urlimg="urlimg"  :honorCoverValue="honorCover"/>
      </transition>
      <transition name="qhtz" appear >
          <TitWorld msg="产业资讯" />
      </transition>
      <transition name="qhtz" appear >
          <CyzxCov v-if="cygkcyzxList.length>0" :urlimg="urlimg" :cygkcyzxListValue="cygkcyzxList" />
      </transition>
    </div>
    <div class="home_right">
        <transition name="zdcy" appear >
            <TitWorld msg="产业资源" />
        </transition>
        <transition name="zdcy" appear >
          <ZzmjCov v-if="zzmjCover" :zzmjCoverValue="zzmjCover"  />
      </transition>
        <transition name="jyzt" appear >
            <TitWorld msg="产值产量" />
        </transition>
        <transition name="jyzt" appear >
            <CzclCov v-if="clczCover" :clczCoverValue="clczCover"/>
        </transition>
        <transition name="znwl" appear >
            <TitWorld msg="龙头企业" />
        </transition>
        <transition name="znwl" appear >
            <JgzsCov v-if="ltqyCover" :urlimg="urlimg" :ltqyCoverValue="ltqyCover" />
        </transition>
    </div>
    <transition name="topcov" appear >
        <TopCov v-if="plantStatistcCover" :plantStatistcCoverValue="plantStatistcCover" />
    </transition> 
    <transition name="topcov" appear >
        <BtmCov v-if="fxStatistcCover" :fxStatistcCoverValue="fxStatistcCover" />
    </transition> 
  </div>
</template>

<script>
// @ is an alias to /src
import TitWorld from "@/components/home/listtit.vue";
import YkgkCov from "@/components/home/ykgkCov.vue";
import RyzsCov from "@/components/home/ryzsCov.vue";
import CyzxCov from "@/components/home/cyzxCov.vue";
import CzclCov from "@/components/home/czclCov.vue";
import ZzmjCov from "@/components/home/zzmjCov.vue";
import JgzsCov from "@/components/home/jgzsCov.vue";
import TopCov from "@/components/home/topCov.vue";
import BtmCov from "@/components/home/btmCov.vue";
import { cygkLeft, cygkcyzx } from'@/api'
export default {
  name: "HomeView",
  components: {
    TitWorld,
    YkgkCov,
    TopCov,
    BtmCov,
    RyzsCov,
    CyzxCov,
    CzclCov,
    ZzmjCov,
    JgzsCov
  },
  data(){
    return{
      auths:'KBISPX',
      urlimg:this.baseImg,
      cygkCover:'',
      honorCover:'',
      clczCover:'',
      zzmjCover:'',
      fxStatistcCover:'',
      plantStatistcCover:'',
      ltqyCover:'',
      cygkcyzxList:[]
    }
  },
  created(){
    this.cygkLeftCover()
    this.cygkcyzxCover()
  },
  mounted(){
    
  },
  methods:{
    cygkLeftCover(){
      cygkLeft(this.auths).then(res=>{
        this.cygkCover = res.result.cygk
        this.honorCover = res.result.honor
        this.clczCover = res.result.clcz 
        this.zzmjCover = res.result.zzmj
        this.fxStatistcCover = res.result.fxStatistc
        this.plantStatistcCover = res.result.plantStatistc
        this.ltqyCover = res.result.ltqy
      })
    },
    cygkcyzxCover(){
      cygkcyzx(this.auths).then(res=>{
          this.cygkcyzxList = res.result.records
      })
    }
  }
};
</script>

<style lang="less">
@import "@/assets/css/common.css";
@import "@/assets/css/home.css";
.yqgk-enter-active {
  animation: fadeInLeft 1s linear 0s ;
}
.dlhj-enter-active {
  animation: fadeInLeft 1.5s linear 0s ;
}
.qhtz-enter-active {
  animation: fadeInLeft 2s linear 0s ;
}
.zdcy-enter-active {
  animation: fadeInRight 1s linear 0s ;
}
.jyzt-enter-active {
  animation: fadeInRight 1.5s linear 0s ;
}
.znwl-enter-active {
  animation: fadeInRight 2s linear 0s ;
}
.topcov-enter-active {
  animation: fadeIn 1.5s linear 0s ;
}
// .run-leave-active {
//   animation: lightSpeedOutRight 2s linear 0s;
// }
</style>
