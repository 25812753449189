import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';


//样式文件需要单独引入
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 数字动态加载
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 引入echarts
import * as echarts from 'echarts'
import 'echarts-gl';


// 引入动画库
import "animate.css"

// 列表滚动
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// 图片预览放大
import Viewer from 'v-viewer'  //点击图片大图预览
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({  //默认样式，可以按需求更改
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

import EZUIKit from 'ezuikit-js';

//全局导入，在main.js中导入
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;

// 路由守卫开始
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const requireAuth = to.meta.requireAuth
  // 判断该路由是否需要登录权限
  // if (requireAuth) {
  //   if (window.sessionStorage.getItem('token')) {
  //     next()
  //   } else {
  //     // Message({
  //     //   message: "用户未登录",
  //     //   type: 'waring'
  //     // }),
  //     next('/Login')
  //   }
  // } else {
  //   next()  // 确保一定要有next()被调用
  // }
  if (requireAuth) {
    if (localStorage.getItem('myname')=='pdadmin'&&localStorage.getItem('mypassword')=='pd2024') {
      next()
    } else {
      // Message({
      //   message: "用户未登录",
      //   type: 'waring'
      // }),
      next('/Login')
    }
  } else {
    next()  // 确保一定要有next()被调用
  }
})
// 路由守卫完成

Vue.config.productionTip = false;

Vue.prototype.baseImg = process.env.VUE_APP_API_URL
Vue.prototype.urlImg = process.env.VUE_APP_IMG_URL
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
